
// Import Bootstrap source files
@use "@angular/material" as mat;

// Override Boostrap variables
@import "bootstrap-variables";
// @import "node_modules/bootstrap/scss/bootstrap";

@import "bootstrap/scss/bootstrap";

// Import core styles
@import"core/core";

// Override core variables
@import "core-variables";

// Import UI Kits
@import "uikits/uikits";

// Custom styles
@import "custom";

@import "@ng-select/ng-select/themes/default.theme.css";

@include mat.core();

@import "../../node_modules/@angular/material/datepicker/datepicker-legacy-compat";
@import "../../node_modules/@angular/material/datepicker/datepicker-theme";

.licenseTable {

  padding: 10px;

  border: "1px solid black";

  text-align: center !important;

  background-color: "white";

}

.licenseTableMostPopular {
  background-color: rgb(255, 246, 228);
}


.licenseTablePrice {

  font-size: 20px;

  font-weight: bold;

}

.licenseTableDiscount {

  color: "grey";

  font-size: 15px;

}


.licenseTableSave {
  font-size: 12px;
  color: "red";
  font-weight: bold;
}





:root {
  --blue: #20a8d8;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f86c6b;
  --orange: #f8cb00;
  --yellow: #ffc107;
  --green: #4dbd74;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #73818f;
  --gray-dark: #2f353a;
  --light-blue: #63c2de;
  --primary: #20a8d8;
  --secondary: #c8ced3;
  --success: #4dbd74;
  --info: #63c2de;
  --warning: #ffc107;
  --danger: #f86c6b;
  --light: #f0f3f5;
  --dark: #2f353a;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}


.bg-facebook {
  background-color: #3b5998 !important;
  opacity: 0.2;
}

a.bg-facebook:hover, a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
  background-color: #2d4373 !important;
}

.bg-twitter {
  background-color: #00aced !important;
  opacity: 0.2;
}

a.bg-twitter:hover, a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus {
  background-color: #0087ba !important;
}

.bg-linkedin {
  background-color: #4875b4 !important;
  opacity: 0.2;
}

a.bg-linkedin:hover, a.bg-linkedin:focus,
button.bg-linkedin:hover,
button.bg-linkedin:focus {
  background-color: #395d90 !important;
}

.bg-google-plus {
  background-color: #d34836 !important;
  opacity: 0.2;
}

a.bg-google-plus:hover, a.bg-google-plus:focus,
button.bg-google-plus:hover,
button.bg-google-plus:focus {
  background-color: #b03626 !important;
}

.bg-flickr {
  background-color: #ff0084 !important;
}

a.bg-flickr:hover, a.bg-flickr:focus,
button.bg-flickr:hover,
button.bg-flickr:focus {
  background-color: #cc006a !important;
}

.bg-tumblr {
  background-color: #32506d !important;
}

a.bg-tumblr:hover, a.bg-tumblr:focus,
button.bg-tumblr:hover,
button.bg-tumblr:focus {
  background-color: #22364a !important;
}

.bg-xing {
  background-color: #026466 !important;
}

a.bg-xing:hover, a.bg-xing:focus,
button.bg-xing:hover,
button.bg-xing:focus {
  background-color: #013334 !important;
}

.bg-github {
  background-color: #4183c4 !important;
}

a.bg-github:hover, a.bg-github:focus,
button.bg-github:hover,
button.bg-github:focus {
  background-color: #3269a0 !important;
}

.bg-html5 {
  background-color: #e34f26 !important;
}

a.bg-html5:hover, a.bg-html5:focus,
button.bg-html5:hover,
button.bg-html5:focus {
  background-color: #be3c18 !important;
}

.bg-openid {
  background-color: #f78c40 !important;
}

a.bg-openid:hover, a.bg-openid:focus,
button.bg-openid:hover,
button.bg-openid:focus {
  background-color: #f56f0f !important;
}

.bg-stack-overflow {
  background-color: #fe7a15 !important;
}

a.bg-stack-overflow:hover, a.bg-stack-overflow:focus,
button.bg-stack-overflow:hover,
button.bg-stack-overflow:focus {
  background-color: #df6101 !important;
}

.bg-youtube {
  background-color: #b00 !important;
  opacity: 0.2;
}

a.bg-youtube:hover, a.bg-youtube:focus,
button.bg-youtube:hover,
button.bg-youtube:focus {
  background-color: #880000 !important;
}

.bg-css3 {
  background-color: #0170ba !important;
}

a.bg-css3:hover, a.bg-css3:focus,
button.bg-css3:hover,
button.bg-css3:focus {
  background-color: #015187 !important;
}

.bg-dribbble {
  background-color: #ea4c89 !important;
}

a.bg-dribbble:hover, a.bg-dribbble:focus,
button.bg-dribbble:hover,
button.bg-dribbble:focus {
  background-color: #e51e6b !important;
}

.bg-instagram {
  background-color: #517fa4 !important;
  opacity: 0.2;
}

a.bg-instagram:hover, a.bg-instagram:focus,
button.bg-instagram:hover,
button.bg-instagram:focus {
  background-color: #406582 !important;
}

.bg-pinterest {
  background-color: #cb2027 !important;
  opacity: 0.2;
}

a.bg-pinterest:hover, a.bg-pinterest:focus,
button.bg-pinterest:hover,
button.bg-pinterest:focus {
  background-color: #9f191f !important;
}

.bg-vk {
  background-color: #45668e !important;
}

a.bg-vk:hover, a.bg-vk:focus,
button.bg-vk:hover,
button.bg-vk:focus {
  background-color: #344d6c !important;
}

.bg-yahoo {
  background-color: #400191 !important;
}

a.bg-yahoo:hover, a.bg-yahoo:focus,
button.bg-yahoo:hover,
button.bg-yahoo:focus {
  background-color: #2a015e !important;
}

.bg-behance {
  background-color: #1769ff !important;
}

a.bg-behance:hover, a.bg-behance:focus,
button.bg-behance:hover,
button.bg-behance:focus {
  background-color: #0050e3 !important;
}

.bg-dropbox {
  background-color: #007ee5 !important;
}

a.bg-dropbox:hover, a.bg-dropbox:focus,
button.bg-dropbox:hover,
button.bg-dropbox:focus {
  background-color: #0062b2 !important;
}

.bg-reddit {
  background-color: #ff4500 !important;
}

a.bg-reddit:hover, a.bg-reddit:focus,
button.bg-reddit:hover,
button.bg-reddit:focus {
  background-color: #cc3700 !important;
}

.bg-spotify {
  background-color: #7ab800 !important;
}

a.bg-spotify:hover, a.bg-spotify:focus,
button.bg-spotify:hover,
button.bg-spotify:focus {
  background-color: #588500 !important;
}

.bg-vine {
  background-color: #00bf8f !important;
}

a.bg-vine:hover, a.bg-vine:focus,
button.bg-vine:hover,
button.bg-vine:focus {
  background-color: #008c69 !important;
}

.bg-foursquare {
  background-color: #1073af !important;
}

a.bg-foursquare:hover, a.bg-foursquare:focus,
button.bg-foursquare:hover,
button.bg-foursquare:focus {
  background-color: #0c5480 !important;
}

.bg-vimeo {
  background-color: #aad450 !important;
}

a.bg-vimeo:hover, a.bg-vimeo:focus,
button.bg-vimeo:hover,
button.bg-vimeo:focus {
  background-color: #93c130 !important;
}

.bg-blue {
  background-color: #20a8d8 !important;
}

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #1985ac !important;
}

.bg-indigo {
  background-color: #6610f2 !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important;
}

.bg-purple {
  background-color: #6f42c1 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #59339d !important;
}

.bg-pink {
  background-color: #e83e8c !important;
}

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important;
}

.bg-red {
  background-color: #f86c6b !important;
}

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #f63c3a !important;
}

.bg-orange {
  background-color: #f8cb00 !important;
}

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #c5a100 !important;
}

.bg-yellow {
  background-color: #ffc107 !important;
}

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #d39e00 !important;
}

.bg-green {
  background-color: #4dbd74 !important;
}

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #3a9d5d !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important;
}

.bg-cyan {
  background-color: #17a2b8 !important;
}

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #117a8b !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important;
}

.bg-gray {
  background-color: #73818f !important;
}

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #5c6873 !important;
}

.bg-gray-dark {
  background-color: #2f353a !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #181b1e !important;
}

.bg-light-blue {
  background-color: #63c2de !important;
}

a.bg-light-blue:hover, a.bg-light-blue:focus,
button.bg-light-blue:hover,
button.bg-light-blue:focus {
  background-color: #39b2d5 !important;
}

.bg-gray-100 {
  background-color: #f0f3f5 !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #d1dbe1 !important;
}

.bg-gray-200 {
  background-color: #e4e7ea !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #c7ced4 !important;
}

.bg-gray-300 {
  background-color: #c8ced3 !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #acb5bc !important;
}

.bg-gray-400 {
  background-color: #acb4bc !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #909ba5 !important;
}

.bg-gray-500 {
  background-color: #8f9ba6 !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #73828f !important;
}

.bg-gray-600 {
  background-color: #73818f !important;
}

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #5c6873 !important;
}

.bg-gray-700 {
  background-color: #5c6873 !important;
}

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #454e57 !important;
}

.bg-gray-800 {
  background-color: #2f353a !important;
}

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #181b1e !important;
}

.bg-gray-900 {
  background-color: #23282c !important;
}

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #0c0e10 !important;
}

.brand-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
}


.brand-card-header {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 6rem;
  border-radius: 0.25rem 0.25rem 0 0;
}

.brand-card-header i {
  font-size: 2rem;
  color: #fff;
}

.brand-card-header .chart-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.brand-card-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0.75rem 0;
  text-align: center;
}

.brand-card-body > * {
  -ms-flex: 1;
  flex: 1;
  padding: 0.1875rem 0;
}

.brand-card-body > *:not(:last-child) {
  border-right: 1px solid #c8ced3;
}

@import '../../node_modules/ngx-toastr/toastr.css';
@import '../../node_modules/@swimlane/ngx-datatable/index.css';
@import '../../node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '../../node_modules/@swimlane/ngx-datatable/assets/icons.css';

