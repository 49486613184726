.badge-pill {
  border-radius: $badge-pill-border-radius;
}

@mixin badge-variant($variant) {
  background-color: $variant;
}

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}
