//
// RTL Support
//
// scss-lint:disable NestingDepth, SelectorDepth
*[dir="rtl"] {
  direction: rtl;
  unicode-bidi: embed;

  ul {
    -webkit-padding-start: 0;
  }

  table tr th {
    text-align: right;
  }

  // Breadcrumb

  .breadcrumb-item {
    float: right;
  }

  .breadcrumb-menu {
    right: auto;
    left: $breadcrumb-padding-x;
  }

  // Dropdown
  .dropdown-item {
    text-align: right;

    i {
      margin-right: -10px;
      margin-left: 10px;
    }

    .badge {
      right: auto;
      left: 10px;
    }
  }

  //
  // Sidebar
  //
  .sidebar-hidden {
    .sidebar {
      margin-right: - $sidebar-width;
    }
  }

  .sidebar-fixed {
    .main, .app-footer {
      margin-right: $sidebar-width;
    }

    &.sidebar-hidden {
      .main, .app-footer {
        margin-right: 0;
      }
    }
  }

  .sidebar-minimized {
    .sidebar {
      flex: 0 0 $sidebar-minimized-width;
    }

    &.sidebar-hidden {
      .sidebar {
        margin-right: - $sidebar-minimized-width;
        margin-left: 0;
      }
    }

    &.sidebar-fixed {
      .main, .app-footer {
        margin-right: $sidebar-minimized-width;
      }

      &.sidebar-hidden {
        .main, .app-footer {
          margin-left: 0;
        }
      }
    }
  }

  //
  // Aside Menu
  //
  .aside-menu-hidden {
    .aside-menu {
      margin-right: 0;
      margin-left: - $aside-menu-width;
    }
  }

  .aside-menu-fixed {
    .aside-menu {
      right: auto;
      left: 0;
    }

    .main, .app-footer {
      //margin-right: 0;
      margin-left: $aside-menu-width;
    }

    &.aside-menu-hidden {
      .main, .app-footer {
        margin-left: 0;
      }
    }
  }

  .aside-menu-off-canvas {
    .aside-menu {
      position: fixed;
      right: 0;
      z-index: $zindex-sticky - 1;
      height: 100%;

      .tab-content {
        height: calc(100vh - #{$aside-menu-nav-padding-y * 2 + $font-size-base} - #{$navbar-height});
      }
    }
  }

  // Sidebar Menu
  .sidebar {
    .sidebar-nav {
      .nav {
        .nav-item {
          .nav-link {
            direction: rtl;
            i {
              margin: 0 0 0 ($sidebar-nav-link-padding-x * 0.5);
            }
            .badge {
              float: left;
              margin-top: 2px;
              // margin-left: 10px;
            }
            &.nav-dropdown-toggle {
              &::before {
                position: absolute;
                right: auto !important;
                left: $sidebar-nav-link-padding-x;
                transform: rotate(180deg);
              }
            }
          }
          &.nav-dropdown {
            &.open {
              > .nav-link.nav-dropdown-toggle::before {
                transform: rotate(270deg);
              }
            }
          }
        }
      }
    }
  }

  .sidebar-minimized .sidebar {
    .nav-link {
      padding-right: 0;
      i {
        float: right;
        padding: 0;
        margin: 0;
      }
      .badge {
        right: auto;
        left: 15px;
      }
    }
    .nav > .nav-dropdown {

      &:hover {
        > .nav-dropdown-items {
          right: $sidebar-minimized-width;
          left: 0;
        }
      }
    }
  }

  // Horizontal bars
  .horizontal-bars {

    li {

      .bars {
        padding-right: 100px;
        padding-left: 0;

        .progress:first-child {
          margin-bottom: 2px;
        }
      }
    }

    &.type-2 {

      li {

        i {
          margin-right: 5px;
          margin-left: $spacer;
        }

        .value {
          float: left;
          font-weight: 600;
        }

        .bars {
          padding: 0;
        }
      }
    }
  }

  // Icon list
  .icons-list {

    li {
      position: relative;
      height: 40px;
      vertical-align: middle;

      i {
        float: right;
      }

      .desc {
        margin-right: 50px;
        margin-left: 0;
      }

      .value {
        right: auto;
        left: 45px;
        text-align: left;

        strong {
          display: block;
          margin-top: -3px;
        }
      }

      .actions {
        right: auto;
        left: 10px;
      }
    }
  }

  // Callouts
  .callout {
    border: 0 solid $border-color;
    border-right-width: .25rem;

    @each $color, $value in $theme-colors {
      &.callout-#{$color} {
        border-right-color: $value;
      }
    }

    .chart-wrapper {
      left: 0;
      float: left;
    }
  }

  .callout-default {
    border-right-color: $text-muted;
  }
}
