// Here you can add other styles


@media (max-width: 544px) {
  TD {
    font-size:10px;
  }

  .main .container-fluid {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .hideOnLowResolution, .breadcrumb, .navbar-brand {
    display: none !important;
  }
  .sidebar {
    width: 200px !important;
  }

}

@media (min-width: 544px) {

  .showOnLowResolution, .breadcrumb, .navbar-brand {
    display: none !important;
  }

}

.toast-bottom-right {
  right: 12px;
  bottom: 52px !important;
}

/* dropdown misaligned */
.ng-dropdown-panel {
  left: 0px;
}

a.disabled{
  cursor: not-allowed !important;
  color: "grey";
}

.delete-project {
  cursor: pointer !important;
  color: "red";
}



.datatable-footer {
  background: none !important;
}
.page-count {
  color: "grey";
}

.datatable-body-cell a {
  text-decoration: none !important;
}

// .date-picker {
//   border: 1.25px solid rgb(204, 204, 204);
//   text-align: right;
//   padding: 6px
// }

/* https://stackoverflow.com/questions/53989445/ngx-toastr-toast-not-showing-in-angular-7 */
#toast-container > div {
  opacity:1;
}
